.App {
  font-family: sans-serif;
}
.chart-div {
  display: flex;
  justify-content: space-around;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  background: #cae2f7;
  box-shadow: 0 8px 6px -6px black;
}
.bold {
  font-weight: bolder;
}
.child-div {
  display: flex;
  justify-items: center;
  text-align: center;
  flex-direction: column;
}
.title {
  font-size: larger;
  text-align: inherit;
}
.details {
  display: flex;
  min-height: 250px;
  justify-content: space-between;
  padding-top: 10px;
}
.zone-child {
  display: flex;
  margin-bottom: 7.5px;
  gap: 7.5px;
}
.zone-range {
  height: 20px;
  width: 150px;
  background-color: hsl(240deg 38% 97%);
}
.zone-title {
  width: 15px;
}
.zone-range-fill {
  position: absolute;
  width: 20px;
  height: 20px;
  z-index: inherit;
}

.list-states {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 6.5px;
  width: 150px;
}
.metric-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  padding: 2px;
}

.metric-button {
  padding: 5px 10px;
  margin: 2.5px;
  border: none;
  cursor: pointer;
  background-color: #f1f1f1;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.metric-button.selected {
  background-color: #4caf50;
  color: white;
}
