@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap");
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  background: #a0a0a0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
li,
a,
strong,
input,
textarea,
::placeholder,
button,
b,
div {
  font-family: "Inter", sans-serif;
}

@-webkit-keyframes infinite-move {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(88px);
    transform: translateX(88px);
  }
}

@-webkit-keyframes infinite-move {
  from {
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  to {
    -ms-transform: translateX(88px);
    transform: translateX(88px);
  }
}

@keyframes infinite-move {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(88px);
    transform: translateX(88px);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* 04/02/2022 css */
.custom_navbar {
}
.cusom_navbar.navbar-nav {
  flex-direction: row;
}
.custom_navbar li {
  display: inline-block;
  padding: 10px 20px;
}
.navbar-nav:last-child li {
  margin-bottom: 0;
  margin-right: 10px;
}
nav.navbar.navbar-light.bg-light {
  background: transparent !important;
  border: none !important;
  box-shadow: none;
}

.custom_navbar li a {
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 500;
  text-decoration: none;
}

.navbar-light .navbar-toggler {
  color: rgb(119 182 234) !important;
  border-color: rgb(119 182 234) !important;
  float: right;
  margin: 0;
}
.calendarScrollContainer {
  height: calc(100vh - 100px); /* Adjust 100px to the height of your top part */
  overflow-y: auto;
}

.nav-container {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 9;
}

.custom_navbar li button#qsSignupBtn {
  border-radius: 30px;
  padding: 7px 20px;
  background: #eceff1;
  color: #000;
  box-shadow: none;
  border: 2px solid #fff;
}

.custom_navbar li button#qsSignupBtn:hover {
  background: #000000;
  color: #fff;
  border: 2px solid #fff;
}

.custom_navbar li button#qsLoginBtn {
  border: 2px solid #eceff1;
  border-radius: 30px;
  padding: 7px 20px;
  color: #eceff1;
}

.custom_navbar li button#qsLoginBtn:hover {
  background: #eceff1;
  color: rgb(0, 0, 0);
  border: 2px solid #eceff1;
}

.custom_navbar li a:hover {
  color: #77b6ea;
}
.navbar-collapse {
  color: #77b6ea;
  display: flex;
}
button#qsLoginBtn {
  border: 2px solid #eceff1;
  border-radius: 30px;
  padding: 7px 20px;
  color: #eceff1;
}
button#qsLoginBtn:hover {
  background: #eceff1;
  color: rgb(0, 0, 0);
  border: 2px solid #eceff1;
}
button#qsSignupBtn {
  border-radius: 30px;
  padding: 7px 20px;
  background: #eceff1;
  color: #000;
  box-shadow: none;
  border: 2px solid #fff;
}
button#qsSignupBtn:hover {
  background: #000000;
  color: #fff;
  border: 2px solid #fff;
}
@media (max-width: 991px) {
  .col-md-5.slider_box {
    width: 100%;
  }
  .nav-container .navbar-nav li span h6 {
    color: #fff;
  }
  .col-md-7.slider_text_box {
    width: 100%;
    margin-top: 50px;
  }
  .price_section .css-fgqrxg-MuiGrid-root > .MuiGrid-item {
    padding-left: 10px;
  }
  .price_section .css-fgqrxg-MuiGrid-root > .MuiGrid-item {
    padding-top: 10px;
  }
  .data_safe_section h2 {
    font-size: 25px;
  }
  .data_safe_section p {
    font-size: 15px;
    line-height: 25px;
  }
  .download_box.col-md-6 {
    width: 50%;
  }
  .col-md-6.download_content_box {
    width: 100%;
  }
  .step_icon_box h6 a {
    font-size: 16px;
  }
  .step_icon_box p {
    font-size: 15px;
  }
  .col-md-6.download_img {
    width: 100%;
    text-align: center;
  }
  .imagetext_section h2 {
    font-size: 22px;
  }
  .banner p {
    font-size: 16px;
  }
  .banner_text_section h2 {
    font-size: 24px;
  }
  .about_section h2 {
    font-size: 22px;
    line-height: 30px;
  }
  .about_section p {
    font-size: 16px;
    line-height: 25px;
  }
  .client_logo_slider h2 {
    font-size: 20px;
    text-align: left;
  }
  .works_section h2 {
    font-size: 22px;
  }
  .col-md-3.logo_box {
    width: 100%;
    text-align: center;
  }
  .col-md-9.nav_box {
    width: 100%;
  }

  nav.navbar.navbar-light.bg-light {
    background: black !important;
    border: none !important;
    box-shadow: none;
  }
  .custom_navbar li {
    display: block;
    padding: 10px 0;
    border-bottom: 1px solid #eceff170;
  }
  .custom_navbar li:last-child {
    border-bottom: none;
  }
  nav.navbar.navbar-light .navbar-toggler {
    color: rgb(255 255 255) !important;
    border-color: rgb(255 255 255) !important;
    float: right;
    margin: 0;
    border-width: 2px;
    background: #fff;
    border-radius: 0;
  }
  .col-md-9.nav_box span.menu_text {
    color: #fff;
    font-size: 18px;
    display: inline-flex;
  }
  .banner_section {
    padding: 0 0 0;
  }
  .hero {
    padding: 0px 0 30px;
  }
  /* nav.navbar .navbar-collapse {
    margin-top: 15px;
    border-top: 2px solid #fff;
  } */
}
@media (max-width: 767px) {
  .price_section .css-11l5t4l-MuiGrid-root {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100%;
  }
  .price_section .css-fgqrxg-MuiGrid-root > .MuiGrid-item {
    padding-top: 30px;
  }
  .navbar-nav li {
    padding: 10px 0;
    border-bottom: 1px solid #eceff170;
  }
  .navbar-nav:last-child li {
    margin-bottom: 0;
  }
  button#qsLoginBtn {
    border: 2px solid #eceff1;
    border-radius: 30px;
    padding: 7px 20px;
    color: #eceff1;
  }
  button#qsLoginBtn:hover {
    background: #eceff1;
    color: rgb(0, 0, 0);
    border: 2px solid #eceff1;
  }

  .navbar-nav:first-child {
    margin-top: 0;
  }
  .navbar-toggler:focus {
    box-shadow: none !important;
  }
  .navbar-nav li:last-child {
    border-bottom: none;
  }
  .navbar-nav:last-child {
    background: #000;
    box-shadow: none;
    margin: 0;
    padding: 0;
  }
  .navbar-nav:last-child li a {
    vertical-align: middle;
    color: #eceff1;
    text-decoration: none;
    text-transform: uppercase;
  }
  .price_section .css-fgqrxg-MuiGrid-root > .MuiGrid-item {
    padding-left: 40px;
  }
  .price_section .css-fgqrxg-MuiGrid-root > .MuiGrid-item {
    padding-top: 30px;
  }
  .banner h1 {
    font-size: 30px;
  }
  .banner p br {
    display: none;
  }
  .client_logo_slider h2 {
    font-size: 20px;
    text-align: center;
  }
  .imagetext_section .about_img {
    text-align: center;
    margin: 30px auto 0;
  }
  .price_section .css-11l5t4l-MuiGrid-root {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 100%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100%;
  }
  .data_safe_section img.about_img,
  .about_section img.about_img {
    margin-bottom: 40px;
  }
  br {
    display: none;
  }
  .about_section {
    padding: 20px 0 70px;
    background: #000;
  }
  .screenshort_custom h4 {
    font-size: 16px;
    margin: 15px 0px 0 0;
  }
}

@media (max-width: 600px) {
  .price_section .css-fgqrxg-MuiGrid-root > .MuiGrid-item {
    margin: 0 15px;
  }
}

@media (max-width: 520px) {
  .banner_text_section .app_link_btn li {
    display: block;
    padding: 0 0 20px 0;
  }
}
.hero {
  max-width: 100%;
  margin: 0 auto;
}

.banner h1 {
  font-size: 80px;
  font-weight: 800;
  color: #eceff1;
  font-family: "Inter", sans-serif;
  text-transform: uppercase;
}

.banner p {
  font-size: 18px;
  font-weight: 500;
  color: #eceff1;
  font-family: "Inter", sans-serif;
}

.banner_section {
  background-image: url("/src/assets/background-coach-scaled2.jpg");
  padding: 70px 0 0;
  background-attachment: scroll;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border: 0 solid transparent;
}

.banner {
  position: relative;
  z-index: 1;
  padding-bottom: 100px;
  padding-top: 150px;
}

.banner a.btn {
  background: #fff;
  border-radius: 30px;
  padding: 10px 25px;
  color: #000000;
  font-weight: 600;
  text-transform: uppercase;
  border: 2px solid #eceff1;
}

.banner a.btn:hover {
  background: #000000;
  color: #fff;
  border-color: rgb(255, 255, 255);
}

.hero {
  padding: 60px 0 30px;
}
.overlay-gradient {
  position: relative;
  z-index: 1;
}

.overlay-gradient::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.7)
  );
  z-index: -1;
}

.custom_footer {
  text-align: left;
  background-color: #f2f2f2b3 !important;
  padding: 60px 0 15px 0;
}

.custom_footer .MuiBox-root.css-16je4mn {
  text-align: left;
}

.custom_footer .MuiBox-root.css-16je4mn p {
  font-size: 16px;
  color: rgb(0, 0, 0);
  margin-top: 20px;
  line-height: 24px;
  margin-bottom: 0;
}

.custom_footer h3 {
  color: rgb(0, 0, 0);
  text-transform: uppercase;
  font-size: 20px;
  font-weight: bold;
}

.custom_footer a {
  color: rgb(0, 0, 0);
  font-size: 16px;
  text-decoration: none;
}

.custom_footer a:hover {
  color: #000;
}

.copy_right {
  text-align: center;
  margin-top: 30px !important;
  border-top: 1px dashed rgb(0, 0, 0);
}

.copy_right p {
  padding: 15px 0 0 0;
  margin: 0;
  color: rgb(0, 0, 0);
  font-size: 16px;
}

.review_btn {
  text-align: center;
  padding: 30px 0;
}

.client_review_section {
  margin-bottom: 50px;
}

.banner_text_section {
  background-color: #000;
  padding-bottom: 40px;
}

.banner_text_section h2 {
  color: #fff;
  font-size: 50px;
  font-weight: 700;
  text-transform: capitalize;
}

.banner_text_section p {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
}

.about_section img {
  width: auto;
  max-width: 100%;
  text-align: right;
  float: right;
}

.about_section {
  padding: 70px 0;
  background: #000;
}

.banner_text_section a img.app_btn {
  width: 190px;
}

.about_section .app_link_btn {
  text-align: left;
}

.about_section h5 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
}

.about_section h2 {
  font-size: 40px;
  font-weight: 700;
  text-transform: capitalize;
  margin-top: 15px;
  color: rgb(255, 255, 255);
}

.about_section p {
  font-size: 20px;
  font-weight: 500;
  color: rgb(255, 255, 255);
  line-height: 34px;
}

.imagetext_section {
  position: relative;
  border: 0 solid transparent;
  background-color: #fafafa;
  padding-top: 70px;
}

.banner_text_section .app_link_btn li {
  display: inline-block;
  padding: 0 30px 0 0;
}

.banner_text_section .app_link_btn {
  text-align: center;
  margin-top: 15px;
}

.client_logo .css-xg2nla-MuiPaper-root-MuiCard-root {
  background-color: transparent;
  text-align: center;
}

.client_logo .css-547jv7-MuiCardMedia-root {
  background-color: transparent;
}

.client_logo .css-1r6ij76 {
  background-color: transparent !important;
}

.client_logo {
  padding: 60px 0;
  background-color: #f2f2f2;
}

.client_logo_slider h2 {
  font-size: 40px;
  font-weight: 700;
  color: #000;
  margin-bottom: 10px;
}

.client_logo_slider .logo_item img {
  width: 150px;
  text-align: center;
  display: inline-block;
  border-radius: 10px;
  padding: 10px 10px;
}

.imagetext_section h2 {
  font-size: 40px;
  font-weight: 800;
  text-transform: capitalize;
  margin-top: 15px;
  color: #000;
}

.imagetext_section h5 {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 800;
  color: #000;
}

.imagetext_section ul li {
  font-size: 18px;
  font-weight: 500;
  color: rgb(0 0 0);
  line-height: 34px;
}

.imagetext_section p {
  font-size: 20px;
  font-weight: 500;
  color: rgb(0 0 0);
  line-height: 34px;
}

.client_logo_slider {
  background: #f2f2f2;
  padding: 30px 0 30px;
}

.client_logo_slider .slick-dots {
  bottom: -30px;
}

.client_logo .css-o5lq4d-MuiGrid-root {
  gap: 10px;
  row-gap: 20px;
  justify-content: center;
}

.client_logo .css-o69gx8-MuiCardMedia-root {
  width: auto;
  text-align: center;
  display: inline-block;
  background: #fff;
  border-radius: 10px;
  padding: 0 10px;
}

.client_logo .css-tu583h-MuiGrid-root {
  -webkit-flex-basis: 18%;
  -ms-flex-preferred-size: 18%;
  flex-basis: 18%;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  max-width: 18%;
  text-align: center;
  align-content: center;
  row-gap: 20px;
}

.screenshort_custom {
  margin: 70px 0;
}

.heading-divider {
  position: relative;
  height: 5px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  background-color: #eceff1;
  width: 90px;
  overflow: hidden;
}

.heading-divider:after {
  content: "";
  position: absolute;
  left: 0;
  top: -1.1px;
  height: 7px;
  width: 8px;
  background-color: #000000;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-name: infinite-move;
}

.client_logo h2 {
  font-size: 40px;
  font-weight: 700;
  color: #000;
  margin-bottom: 10px;
}

.client_logo .heading-divider {
  margin: 0 auto;
  margin-bottom: 30px;
}

.swiper-button-prev,
.swiper-button-next {
  background-color: #b2e2f0;
  width: 50px;
  padding: 30px;
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: 0 !important;
  right: auto;
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: 0px !important;
  left: auto;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  color: #fff !important;
  font-size: 30px !important;
}

.screenshort_custom .swiper h4 {
  font-size: 22px;
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 30px;
}

.icon_box {
  text-align: center;
  background: #eceff1;
  padding: 10px;
  border-radius: 10px;
}

.icon_box i {
  font-size: 34px;
  border-radius: 50%;
  margin-bottom: 15px;
  color: #000000;
  text-align: center;
}

.icon_box h5 {
  font-size: 18px;
  font-weight: 700;
  color: rgb(0, 0, 0);
}

.icon_box p {
  font-size: 16px;
  line-height: 28px;
  color: rgb(0, 0, 0);
}

.screenshort_custom h4 {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
  margin-top: 20px;
}

.screenshort_custom .slick-prev,
.screenshort_custom .slick-next {
  position: absolute;
  display: block;
  height: 50px;
  width: 30px;
  line-height: 0;
  font-size: 0px;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  top: 50%;
  border-radius: 0;
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  box-shadow: none;
  transition: opacity 0.4s ease-in-out;
  opacity: 0.25;
  z-index: 1;
}

.screenshort_custom button.slick-arrow.slick-prev {
  left: 0;
}

.screenshort_custom button.slick-arrow.slick-next {
  right: 0;
}

.screenshort_custom .slick-next:before {
  border-style: solid;
  border-width: 2px 2px 0 0;
  content: "";
  display: inline-block;
  height: 10px;
  position: relative;
  top: 0;
  left: 0;
  transform: rotate(45deg);
  vertical-align: top;
  width: 10px;
}

.screenshort_custom .slick-prev:before {
  border-style: solid;
  border-width: 2px 2px 0 0;
  content: "";
  display: inline-block;
  height: 10px;
  position: relative;
  top: 0;
  left: 6px;
  transform: rotate(-135deg);
  vertical-align: top;
  width: 10px;
}

.screenshort_custom .slick-prev:hover,
.screenshort_custom .slick-prev:focus,
.screenshort_custom .slick-next:hover,
.screenshort_custom .slick-next:focus {
  outline: none;
  opacity: 1;
  background: #000;
}

.screenshort_custom .slick-dots li button:before {
  font-size: 13px;
}

.price_section .css-1u8t36f-MuiContainer-root,
.price_section .css-xdmu94-MuiContainer-root {
  max-width: 100%;
  margin: 0;
  padding: 0 0 30px 0;
}

.price_section .css-13lalgr-MuiCardHeader-root {
  background-color: #000000;
  padding: 30px 0;
}

.price_section .css-bhp9pd-MuiPaper-root-MuiCard-root {
  background-color: #eceff1;
  box-shadow: 0 0 15px 4px #0000001c;
  border-radius: 12px;
}

.price_section h2 {
  font-size: 55px;
  color: #000000;
  font-weight: 800;
}

.price_section h6 {
  font-size: 20px;
  color: #000;
  text-transform: capitalize;
}

.price_section ul li {
  font-size: 18px;
  font-weight: 500;
  line-height: 40px;
  text-align: left;
  position: relative;
  padding: 0 0 0 30px;
}

.price_section ul li:before {
  content: "";
  position: absolute;
  background-image: url("/src/assets/check.svg");
  width: 20px;
  height: 20px;
  left: 0;
  top: 10px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.price_section a.MuiButton-root {
  width: auto;
  background: #fff;
  border-radius: 30px;
  padding: 10px 25px;
  color: #000000;
  font-weight: 600;
  text-transform: uppercase;
  border: 2px solid #000000;
  box-shadow: none;
}

.price_section {
  background-image: url("/src/assets/pricing-bg.png");
  padding: 60px 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.price_section .MuiCardActions-root {
  justify-content: left;
  margin-bottom: 30px;
  padding: 0 40px;
  margin-top: 20px;
}

.price_section a.MuiButton-root:hover {
  background: #000000;
  color: #fff;
  border-color: rgb(0, 0, 0);
}

.price_section .css-13lalgr-MuiCardHeader-root span {
  color: #fff;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 25px;
}

.price_section .heading-divider {
  margin: 0 auto;
}

.price_section .css-46bh2p-MuiCardContent-root {
  padding: 15px 40px;
}

.price_section .heading-divider {
  margin: 15px auto 0;
}

.price_section h3 {
  font-size: 40px;
  font-weight: 800;
  color: rgb(255, 255, 255);
}

.next-steps {
  padding: 0;
}

.custom_step_section .heading-divider {
  margin: 0 auto 30px;
}

.custom_step_section h2 {
  margin-bottom: 15px;
  font-size: 40px;
  font-weight: 800;
  text-transform: capitalize;
  color: #000;
}

.step_icon_box {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 5px 3px #0000001c;
  padding: 20px 30px;
  margin-bottom: 30px;
  min-height: 120px;
}

.step_icon_box h6 a {
  color: #000000;
  font-size: 21px;
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 700;
}

.step_icon_box p {
  font-size: 16px;
  color: #000;
  font-weight: 500;
}

.step_icon_box h6 a:hover {
  color: #000000;
}

.custom_step_section {
  margin: 70px 0;
}

.testimonial_section h2 {
  font-size: 40px;
  font-weight: 800;
  color: #000;
  text-align: center;
}

.testimonial_section span {
  font-size: 18px;
  color: #000;
  text-align: center !important;
  font-weight: 500;
}

.testimonial_section span {
  font-size: 18px;
  color: #000;
  text-align: center !important;
  font-weight: 500;
}

.testimonial_section .MuiBox-root.css-0 {
  text-align: center;
  margin-bottom: 30px;
}

.testimonial_section .css-1ps6pg7-MuiPaper-root {
  background: transparent;
  box-shadow: none;
  max-width: 600px;
  margin: 0 auto;
}

.testimonial_section h6 {
  color: #000000;
  font-size: 28px;
  font-weight: 800;
}

.testimonial_section p {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.makeStyles-root-1 {
  padding: 60px 0;
  background: #dcf4fb;
}

.works_section h2 {
  font-size: 40px;
  font-weight: 800;
  text-transform: capitalize;
  margin-top: 15px;
  color: #000;
  text-align: center;
  margin-bottom: 40px;
}

.works_section h5 {
  text-align: center;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 800;
  color: #000;
}

.works_section h3 {
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 700;
  color: #000;
}

.works_section p {
  font-size: 16px;
  font-weight: 500;
  color: rgb(0 0 0);
  line-height: 25px;
}

.works_section {
  margin-top: 70px;
}

.data_safe_section h5 {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 800;
  color: #000;
}

.data_safe_section h2 {
  font-size: 40px;
  font-weight: 800;
  text-transform: capitalize;
  margin-top: 15px;
  color: #000;
}

.data_safe_section p {
  font-size: 20px;
  font-weight: 500;
  color: rgb(0 0 0);
  line-height: 34px;
}

.data_safe_section a.btn {
  width: auto;
  background: #fff;
  border-radius: 30px;
  padding: 10px 25px;
  color: #000000;
  font-weight: 600;
  text-transform: uppercase;
  border: 2px solid #000000;
  box-shadow: none;
}

.data_safe_section a.btn:hover {
  background: #000000;
  color: #fff;
  border-color: rgb(0, 0, 0);
}

.data_safe_section {
  position: relative;
  border: 0 solid transparent;
  background-color: #fafafa;
  padding-top: 70px;
  padding-bottom: 80px;
}

.review_text {
  border: 2px solid #ddd;
  padding: 20px;
  border-radius: 20px;
  min-height: 180px;
}

.info_textbox {
  display: flex;
  gap: 20px;
  vertical-align: middle;
  align-items: center;
}

.autur_info h5 {
  font-size: 18px;
  margin: 0;
  font-weight: 600;
}

.info_textbox .autur_img img {
  border-radius: 50%;
  width: 60px;
}

.review_text p {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  text-align: left !important;
}

.client_review_section h2 {
  text-align: center;
  margin-bottom: 15px;
  font-size: 40px;
  font-weight: 800;
  text-transform: capitalize;
  color: #000;
}

.client_review_section p {
  text-align: center;
  font-size: 18px;
  color: #000;
  font-weight: 500;
  margin-bottom: 30px;
}

.client_review_section a.btn {
  width: auto;
  background: #fff;
  border-radius: 30px;
  padding: 10px 25px;
  color: #000000;
  font-weight: 600;
  text-transform: uppercase;
  border: 2px solid #000000;
  box-shadow: none;
}

.client_review_section a.btn:hover {
  background: #000000;
  color: #fff;
  border-color: rgb(0, 0, 0);
}

img {
  max-width: 100%;
}

.col-md-9.nav_box span.menu_text {
  display: none;
}

.slick-slide .service_item img {
  text-align: center;
  display: inline-block;
}

.service_item {
  text-align: center;
  margin: 0 auto;
}

.cyclingevents,
.runningevents,
.audaxresults,
.zoneSetting,
.zoneBreakup,
.workoutTextView,
.updateTrainingPeaks,
.testimonials,
.signup,
.setupStepperMobile,
.setupStepper,
.settingsPhoneNumber,
.privacypolicy,
.calendar,
.dashboard,
.Profile,
.pricing,
.zones,
.invoices,
.newuser,
.settings,
.profile,
.Home,
.fitness,
.fitnessForm,
.helperText,
.fitnessFormChart,
.connections,
.ExternalApi {
  position: relative;
  background: #000;
  margin-bottom: 10px;
}
.calendar_section {
  margin-bottom: 10px;
  max-width: 1200px; /* Adjust this value to your desired maximum width */
  margin-left: auto;
  margin-right: auto;
  width: 100%; /* Ensures it takes the full width until the max-width */
  padding-left: 5px; /* Optional: for some internal spacing */
  padding-right: 5px; /* Optional: for some internal spacing */
}

.calender_section h4 {
  font-size: 35px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 30px;
}

.calender_section .fc-scrollgrid-sync-inner {
  padding: 15px 0;
}

.calender_section h6 {
  width: 100%;
  max-width: 100% !important;
  color: #000 !important;
  font-size: 28px;
  font-weight: 700;
}

.calender_section .css-utvaoo-MuiPaper-root {
  max-width: 100%;
  color: #000;
}

.calender_section .css-h93ljk-MuiTypography-root {
  font-size: 20px;
  font-weight: 700;
}

.calender_section table tr td {
  padding: 16px 0;
  font-size: 16px;
}

.calender_section h2 {
  font-size: 18px !important;
}

.calender_section h2 {
  font-size: 18px !important;
}

.calender_section .css-1mbunh-MuiPaper-root {
  box-shadow: none;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.zone_section h5 {
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 15px;
  margin-top: 15px;
}

.zone_section td,
th {
  text-align: left;
}

.zone_section tr:nth-child(even) {
  background-color: #dddddd;
}

.zone_section table thead tr th {
  font-size: 16px !important;
  font-weight: 600;
  text-align: left;
  color: #000;
  padding: 15px 15px;
}

.zone_section table tbody tr td {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  padding: 15px 15px;
}

.zone_section .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  box-shadow: none;
  border: 1px solid #ddd;
}

.zone_section {
  margin-bottom: 0px;
}

.event_section h4 {
  font-size: 35px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 30px;
}

.event_section td,
th {
  text-align: left;
}

.event_section tr:nth-child(even) {
  background-color: #dddddd;
}

.event_section table thead tr th {
  font-size: 16px;
  font-weight: 600;
}

.event_section table thead tr th .MUIDataTableHeadCell-data-54 {
  font-size: 16px;
  font-weight: 600;
}

.event_section table tbody tr td {
  font-size: 14px;
  font-weight: 500;
  color: #000;
}

.event_section table tbody tr td a {
  color: #000;
  text-decoration: none;
  font-weight: 700;
}

.event_section .css-11mde6h-MuiPaper-root {
  margin-bottom: 70px;
  box-shadow: 0 0 18px 3px #0000001f;
  border: none;
}

.privacy_policy .css-3su884-MuiPaper-root {
  box-shadow: none;
}

.privacy_policy h2 {
  font-size: 35px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 30px;
}

.privacy_policy p h5 {
  font-size: 20px;
  font-weight: 700;
  padding: 10px 0;
}

.privacy_policy p {
  font-size: 17px;
  line-height: 30px;
}

.privacy_policy p b {
  font-size: 16px;
}

.privacy_policy {
  padding-bottom: 60px;
}

.nav-container .profile_menu li a.nav-link {
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 500;
  text-decoration: none;
}

.nav-container .profile_menu li a.nav-link.active {
  font-size: 14px;
  text-transform: uppercase;
  color: #eab477;
  font-weight: 500;
  text-decoration: none;
}

.nav-container .profile_menu li a.nav-link:hover {
  color: #eab477;
}

.setting_box .css-1rktqxf {
  max-width: 100%;
  width: 100%;
  padding: 5px 0;
  color: #000 !important;
  font-weight: 500;
}

.setting_box {
  box-shadow: none !important;
}

.setting_box .css-14epxft-MuiPaper-root {
  box-shadow: none;
  border: 1px solid #ccc;
  background: #f4f4f4;
  padding: 15px !important;
}

.setting_box h4 {
  color: #000;
  font-weight: 800;
  font-size: 35px;
}

.setting_box h6 {
  color: #000;
  font-weight: 500;
  font-size: 22px;
  margin-top: 20px;
}

.setting_box .MuiBox-root.css-1x1xx5k {
  width: 100%;
  max-width: 100%;
}

.setting_box .css-rh92k-MuiPaper-root-MuiMobileStepper-root {
  background: transparent;
}

.profile_box .css-ag7rrr-MuiTypography-root {
  font-size: 25px;
  color: #000 !important;
  font-weight: 700;
  margin: 15px 0;
}

.profile_box .MuiGrid-root typography {
  color: #000 !important;
  font-size: 13px;
  font-weight: 600;
}

.profile_box .MuiPaper-root {
  box-shadow: none !important;
  border: 1px solid #ccc;
  background: #f4f4f4;
  padding: 15px !important;
}

.profile_box .css-1oqqzyl-MuiContainer-root {
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.profile_box .css-1jv1fsg-MuiTableCell-root.MuiTableCell-head {
  color: #000;
  font-size: 15px;
  padding: 0;
  border-bottom: none;
}

.profile_box .css-1aznpnh-MuiSlider-root {
  color: #000000;
}

.profile_box .css-1b9imn5-MuiInputBase-root-MuiInput-root:before {
  border-bottom: none;
}

.profile_box .css-1b9imn5-MuiInputBase-root-MuiInput-root {
  background-color: transparent;
  font-size: 20px;
  font-weight: 600;
}

.profile_box h6 {
  font-size: 16px;
  font-weight: 500;
}

.profile_box .react-tel-input input {
  font-size: 15px;
  font-weight: 600;
}

.setting_section {
  margin-bottom: 50px;
  margin-top: 50px;
}

.profile_box .css-16ec6oy-MuiButtonBase-root-MuiButton-root {
  color: #000;
  border: 1px solid #000;
  border-radius: 30px;
  padding: 10px 0;
  background: #fff;
}

.profile_box .css-16ec6oy-MuiButtonBase-root-MuiButton-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgb(0 0 0 / 4%);
  border: 1px solid #000000;
}
.zone_section ul li {
  font-size: 16px;
  line-height: 28px;
}
@media (max-width: 1199px) {
  .custom_navbar li {
    display: inline-block;
    padding: 10px 8px;
  }
  .custom_navbar li a {
    font-size: 13px;
  }
  .custom_navbar li button#qsLoginBtn {
    padding: 7px 15px;
    font-size: 13px;
  }
  .custom_navbar li button#qsSignupBtn {
    padding: 7px 15px;
    font-size: 13px;
  }
  .banner h1 {
    font-size: 50px;
  }
  .banner_text_section h2 {
    font-size: 35px;
  }
  .banner_text_section p {
    font-size: 16px;
  }
  .about_section h2 {
    font-size: 30px;
  }
  .about_section p {
    font-size: 18px;
    line-height: 30px;
  }
  .client_logo_slider h2 {
    font-size: 30px;
    text-align: left;
  }
  .imagetext_section h2 {
    font-size: 30px;
  }
  .imagetext_section p {
    font-size: 16px;
    line-height: 28px;
  }
  .works_section h2 {
    font-size: 30px;
  }
  .screenshort_custom h4 {
    font-size: 16px;
  }
  .icon_box p {
    font-size: 17px;
  }
  .icon_box h5 {
    font-size: 16px;
  }
  .price_section .css-13lalgr-MuiCardHeader-root span {
    font-size: 18px;
  }
  .price_section h2 {
    font-size: 35px;
  }
  .price_section .css-46bh2p-MuiCardContent-root {
    padding: 15px 15px;
  }
  .price_section ul li {
    font-size: 16px;
    line-height: 33px;
  }
  .price_section .MuiCardActions-root {
    justify-content: left;
    margin-bottom: 30px;
    padding: 0 20px;
    margin-top: 20px;
  }
  .data_safe_section h2 {
    font-size: 30px;
  }
  .data_safe_section p {
    font-size: 18px;
    line-height: 30px;
  }
  .custom_step_section h2 {
    font-size: 30px;
  }
  .client_review_section h2 {
    font-size: 30px;
  }
  .client_review_section p {
    font-size: 16px;
  }
  .download_box.col-md-6 {
    width: 100%;
  }
}

/* @media (max-width: 600px) {
  .price_section .css-fgqrxg-MuiGrid-root > .MuiGrid-item {
    margin: 0 15px;
  }
}

@media (max-width: 520px) {
  .banner_text_section .app_link_btn li {
    display: block;
    padding: 0 0 20px 0;
  }
} */

/* 04/02/2022 css end */
#app {
  background: #eceff1;
}
.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}

.react-tel-input .form-control {
  width: 100% !important;
  max-width: fit-content;
}
.flex-container {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.flex-item {
  padding: 0;
  margin: 0;
  border: 0.5px solid rgba(204, 204, 204, 0.5);
  border-radius: 5px;
  text-align: center;
  list-style-type: none;
}
.flex-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}

.reps {
  font-size: 1.2em;
  font-weight: bold;
}

.step-card-content {
  margin-left: 20px;
  margin-right: 20px;
  border: none;
}
.step-time {
  display: inline-block;
  width: 75px; /* Adjust based on your layout (50px * 1.5) */
  font-weight: bold;
  color: #333;
}

.step-power {
  display: inline-block;
  width: 90px; /* Adjust based on your layout (60px * 1.5) */
  font-weight: bold;
  color: #007bff; /* Change color based on your preference */
}

.step-wattage {
  display: inline-block;
  color: #6c757d; /* Change color based on your preference */
}
.settings-modal {
  position: fixed;
  right: 0;
  top: 0;
  width: 300px; /* Adjust width as needed */
  height: 100%;
  background: white;
  box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.5);
  /* Transition for smooth sliding effect */
  transition: right 0.3s;
}
.content-darken {
  filter: blur(2px);
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10; /* Ensure it's above other content but below the modal */
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  animation: fadeIn 0.5s ease-out forwards;
}

/* Additional styles and media queries as needed */
/* Add this CSS in your component's CSS file */
.connectionStatus {
  padding: 20px;
  margin-top: 20px;
  border-radius: 10px;
  background-color: #f4f4f4; /* Light grey; adjust based on your color scheme */
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.connectionStatus h2 {
  margin: 0;
  color: #333; /* Dark grey; adjust based on your color scheme */
  font-size: 24px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  animation: fadeIn 2s;
}

/* Additional styles and media queries as needed */
